import "./../style/footer.scss";
import logo from "./../icon.png";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";

export default function Footer(props: any) {
  return (
    <MDBFooter bgColor="dark" className="text-center text-lg-start text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 pb-0">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div className="margin-icon">
          <MDBBtn
            floating
            className="m-1"
            style={{ backgroundColor: "#3b5998" }}
            href="https://www.facebook.com/profile.php?id=100093597632953"
            role="button"
            target="_blank"
          >
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn>
          {/* 
                    <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: '#dd4b39' }}
                        href='#!'
                        role='button'
                    >
                        <MDBIcon fab icon='google' />
                    </MDBBtn> */}
          <MDBBtn
            floating
            className="m-1"
            style={{ backgroundColor: "#ac2bac" }}
            href="https://www.instagram.com/bukpocketmedia/"
            role="button"
            target="_blank"
          >
            <MDBIcon fab icon="instagram" />
          </MDBBtn>

          <MDBBtn
            floating
            className="m-1"
            style={{ backgroundColor: "#ed302f" }}
            href="https://www.youtube.com/channel/UC_oFYkNyA9V41eqn_7uZ_OA"
            role="button"
            target="_blank"
          >
            <MDBIcon fab icon="youtube" />
          </MDBBtn>
          <MDBBtn
            floating
            className="m-1"
            style={{ backgroundColor: "#55acee" }}
            href="https://twitter.com/Bukpocket"
            role="button"
            target="_blank"
          >
            <MDBIcon fab icon="twitter" />
          </MDBBtn>
          <MDBBtn
            color="success"
            className="m-1"
            style={{ backgroundColor: "red" }}
            role="button"
            onClick={()=>props.form()}
          >
            Join us &nbsp;
            <MDBBadge color="danger">
              ^_^
            </MDBBadge>
          </MDBBtn>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-00">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto">
              <span className="">
                <img src={logo} height={100} width={200} alt="logo" />
              </span>
              <p>Stay tuned for 100% drama</p>
            </MDBCol>
            {/* 
                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                            <p>
                                <a href='#!' className='text-reset'>
                                    hello
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    hello
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    hello
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    hello
                                </a>
                            </p>
                        </MDBCol> */}

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4 mt-40 mt-4"
            >
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Kolkata, India, West Bengal
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                contact.bukpocket@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91-9775163561
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-2 small"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2023 Copyright:
        <a className="text-reset fw-bold" href="#">
          Buk Pocket
        </a>
      </div>
    </MDBFooter>
  );
}
