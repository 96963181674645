import './App.scss';
import About from './components/About';
import Menu from './components/Menu';
import NoMatch from './components/NoMatch';
import Dashboard from './dashboard/Dashboard';
import { Routes, Route} from "react-router-dom";

function App() {
  return (
    <Routes>
        <Route path="/" element={<Menu />}>
          <Route index element={<Dashboard />} />
          <Route path="about" element={<About />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    // <Dashboard></Dashboard>
  );
}

export default App;
