import Footer from '../components/Footer';
import Menu from '../components/Menu';
import MiniSlider from '../components/MiniSlider';
import Slider from '../components/Slider';
import Trending from '../components/Trending';
import Player from '../components/Player';
import { useState } from 'react';
import About from '../components/About';
import CastAndCrew from '../components/CastAndCrew';

export default function Dashboard() {
    const [showModal, setShow] = useState<any>(false);
    const [showPlayer, setPlayer] = useState<any>(false);
    const [showForm, setForm] = useState<any>(false);
    const [url, setUrl] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const openPLayer = (url?: string, title?: string) => {
        setShow(true);
        setPlayer(true);
        setForm(false);
        setUrl(url || '');
        setTitle(title || '')
    }
    const openForm = () => {
        setShow(true);
        setForm(true);
        setPlayer(false);
        setUrl('');
        setTitle('')
    }
    const closePlayer = () => {
        setShow(false);
    }
    return (
        <>
            <Player show={showModal} showPlayer ={showPlayer} showForm={showForm} close={closePlayer} key={Math.random()} url={url} title={title}></Player>
            <Menu></Menu>
            <Slider play={openPLayer}></Slider>
            <CastAndCrew/>
            {/* <About/> */}
            {/* <MiniSlider></MiniSlider> */}
            <Footer form={openForm}></Footer>
        </>
    )
}
