import "./../style/slider.scss";
import poster1 from "../assets/1.jpg";
import poster2 from "../assets/2.png";

import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

export default function Slider(props: any) {
  return (
    <MDBCarousel showIndicators showControls fade touch>
      <MDBCarouselItem
        className="w-100 d-block item-height"
        itemId={1}
        src={poster1}
        alt="..."
        onClick={() =>
          props.play(
            "https://www.youtube.com/embed/Ujy-Gdd-nR8",
            "Nehru's Speech"
          )
        }
      >
        <h6 className="header-title">Nehru's Speech</h6>
        <button
          className="button-28"
          onClick={() =>
            props.play(
              "https://www.youtube.com/embed/Ujy-Gdd-nR8",
              "Nehru's Speech"
            )
          }
        >
          Watch
        </button>
        <p className="desc">
          Witness the unfolding of the tale of love between Ram and Adila this
          Independence Day... #TrystWithDestiny #ThisIndependenceDay
        </p>
      </MDBCarouselItem>

      <MDBCarouselItem
        className="w-100 d-block item-height"
        itemId={2}
        src={poster2}
        alt="..."
        onClick={() =>
          props.play(
            "https://www.youtube.com/embed/Ul6UneQC_7k",
            "Nehru's Speech | Official Glimpse"
          )
        }
      >
        <h6 className="header-title">Nehru's Speech | Official Glimpse</h6>
        <button
          className="button-28"
          onClick={() =>
            props.play(
              "https://www.youtube.com/embed/Ul6UneQC_7k",
              "Nehru's Speech | Official Glimpse"
            )
          }
        >
          Watch
        </button>
        <p className="desc">
          Witness the unfolding of the tale of love between Ram and Adila this
          Independence Day... #TrystWithDestiny #ThisIndependenceDay
        </p>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}
