import { useEffect, useState } from "react";
import "../style/about.scss";
import $ from "jquery";
export default function About() {
  const [crew, setCrew] = useState(`Unmesh Ganguly, Sneha Das, 
Dipankar Sen
Producers - Hima Thakur And 
Swapna Bala
Executive Producer - Sneha Dhar
D.O.P - Saikat Bala
Music Director - Mak-Mallar
Costume Designer - Anrini Bhattacharyya
MakeUp Artist  - Tanima Dutta
Production Manager - Rhituraj
Colorist - Pobitro Pramanik
Art - Aparajita Misra
Assistant Directors - Akash, Malay, 
Anusmita, Keya
Writer Editor Director - Pronoy Dasgupta
Poster design by Moulina Thakur 
and Living Room Storiez
Promo Editor - Tirthankar Roy`);
const [details, setDetails] = useState(`Under the mesmerizing glow of the moon, Ram and Adila step into the pulsating heart of the city, their love a radiant beacon against the backdrop of towering skyscrapers and lonely streets. The city, with its labyrinthine alleyways and hidden corners, becomes a sanctuary of promise, as they venture into uncharted territory, guided solely by the whispers of their hearts and the promises etched in their souls.

We welcome you all to witness the unfolding of the tale of love between Ram and Adila.

Presenting Nehru's Speech`);
  useEffect(() => {
    tabControl();

    /*
    We also apply the switch when a viewport change is detected on the fly
    (e.g. when you resize the browser window or flip your device from 
    portrait mode to landscape). We set a timer with a small delay to run 
    it only once when the resizing ends. It's not perfect, but it's better
    than have it running constantly during the action of resizing.
    */
    var resizeTimer: any;
    $(window).on("resize", function (e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        tabControl();
      }, 250);
    });

    /*
    The function below is responsible for switching the tabs when clicked.
    It switches both the tabs and the accordion buttons even if 
    only the one or the other can be visible on a screen. We prefer
    that in order to have a consistent selection in case the viewport
    changes (e.g. when you esize the browser window or flip your 
    device from portrait mode to landscape).
    */
    function tabControl() {
      var tabs = $(".tabbed-content").find(".tabs");
      if (tabs.is(":visible")) {
        tabs.find("a").on("click", function (event) {
          event.preventDefault();
          var target = $(this).attr("href"),
            tabs = $(this).parents(".tabs"),
            buttons = tabs.find("a"),
            item = tabs.parents(".tabbed-content").find(".item");
          buttons.removeClass("active");
          item.removeClass("active");
          $(this).addClass("active");
          if (target) {
            $(target).addClass("active");
          }
        });
      } else {
        $(".item").on("click", function () {
          var container = $(this).parents(".tabbed-content"),
            currId = $(this).attr("id"),
            items = container.find(".item");
          container.find(".tabs a").removeClass("active");
          items.removeClass("active");
          $(this).addClass("active");
          container.find('.tabs a[href$="#' + currId + '"]').addClass("active");
        });
      }
    }
  }, []);
  return (
    <div className="container-about">
      <article className="tabbed-content tabs-side">
        <nav className="tabs">
          <ul>
            <li>
              <a href="#side_tab1" className="active">
                Details
              </a>
            </li>
            <li>
              <a href="#side_tab2">Full Cast & Crew</a>
            </li>
          </ul>
        </nav>
        <section id="side_tab1" className="item active" data-title="Details">
          <div className="item-content">
            <p>
             {details}
            </p>
            <p>
              The change applies only on large screens. On smaller screens (in
              this example: below 800 pixels) it falls back to the same
              accordion we have on the previous example.
            </p>
          </div>
        </section>
        <section id="side_tab2" className="item" data-title="Crew">
          <div className="item-content">
              <p className="crew"><b>Produced By Bukpocket Media</b><br></br>
              <b>Starring:</b><br></br>
                {crew}</p>
          </div>
        </section>
      </article>
    </div>
  );
}
