import React, { useState } from "react";
import { MDBInput, MDBBtn, MDBTextArea } from "mdb-react-ui-kit";
import "./../style/form.scss";
export default function Form() {
  const [btnText, setButtonText] = useState("Send");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const payload = {
      name: e.target[0].value,
      email: e.target[1].value,
      phone: e.target[2].value,
      message: e.target[3].value,
    };
    if (
      e.target[0].value !== "" &&
      e.target[1].value !== "" &&
      e.target[2].value !== "" &&
      e.target[3].value !== ""
    ) {
      setButtonText("Submitting...");
      const res = fetch("https://bukpocket-api.onrender.com/api/items", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setError(false);
          setButtonText("Submitted");
          e.target[0].value = '';
          e.target[1].value = '';
          e.target[2].value = '';
          e.target[3].value = ''
          setTimeout(() => {
            setButtonText("Send");
          }, 5000);
          return responseJson;
        })
        .catch((error) => {
          setError(true);
          setErrorText("Something went wrong, please try again later!");
          console.error(error);
        });
    } else {
      setError(true);
      setErrorText("Please enter all the fields carefully!");
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <MDBInput id="form4Example1" wrapperClass="mb-4" label="Name" />
      <MDBInput
        type="email"
        id="form4Example2"
        wrapperClass="mb-4"
        label="Email address"
      />
      <MDBInput
        wrapperClass="mb-4"
        type="tel"
        id="form6Example6"
        label="Phone"
      />
      <MDBTextArea label="Message" id="textAreaExample" rows={4} />
      <br></br>
      <MDBBtn type="submit" className="mb-4" block>
        {btnText}
      </MDBBtn>
      {error && <span className="error">{errorText}</span>}
    </form>
  );
}
