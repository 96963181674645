import React, { useEffect, useState } from "react";
import "./../style/player.scss";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Form from "./Form";

export default function Player(props: any) {
  const [basicModal, setBasicModal] = useState(props.show);

  const toggleShow = () => setBasicModal(!basicModal);

  return (
    <>
      <MDBModal
        show={props.show}
        closeOnEsc={false}
        backdrop={false}
        nonInvasive={true}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            {props.showPlayer && (
              <>
                <MDBModalHeader>
                  <MDBModalTitle>{props.title}</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => props.close()}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="hs-responsive-embed-youtube">
                    <iframe
                      src={props.url}
                      allowFullScreen={true}
                      title={props.url}
                    ></iframe>
                  </div>
                </MDBModalBody>
                <MDBModalFooter></MDBModalFooter>
              </>
            )}
            {props.showForm && (<><MDBModalHeader>
                  <MDBModalTitle>Please fill the form</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => props.close()}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <Form/>
                </MDBModalBody>
                <MDBModalFooter></MDBModalFooter>
              </>)}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
