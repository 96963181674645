import { useState } from "react";
import "./../style/cast.scss";
export default function CastAndCrew() {
  const [crew, setCrew] = useState(`
  Unmesh Ganguly, Sneha Das, 
  Dipankar Sen
  Producers - Hima Thakur And 
  Swapna Bala
  Executive Producer - Sneha Dhar
  D.O.P - Saikat Bala
  Music Director - Mak-Mallar
  Costume Designer - Anrini Bhattacharyya
  MakeUp Artist  - Tanima Dutta
  Production Manager - Rhituraj
  Colorist - Pobitro Pramanik
  Art - Aparajita Misra
  Assistant Directors - Akash, Malay, 
  Anusmita, Keya
  Writer Editor Director - Pronoy Dasgupta
  Poster design by Moulina Thakur 
  and Living Room Storiez
  Promo Editor - Tirthankar Roy`);
  const [details, setDetails] =
    useState(`Under the mesmerizing glow of the moon, Ram and Adila step into the pulsating heart of the city, their love a radiant beacon against the backdrop of towering skyscrapers and lonely streets. The city, with its labyrinthine alleyways and hidden corners, becomes a sanctuary of promise, as they venture into uncharted territory, guided solely by the whispers of their hearts and the promises etched in their souls.
We welcome you all to witness the unfolding of the tale of love between Ram and Adila. Presenting Nehru's Speech.`);
  return (
    <span className="acc">
      <div className="col">
        <div className="tabs">
          <div className="tab">
            <input type="checkbox" id="chck1" />
            <label className="tab-label" htmlFor="chck1">
              Full Cast & Crew of Neheru's Speech
            </label>
            <div className="tab-content">
              <h6>
                <b>Details</b>
              </h6>
              <p className="details">{details}</p>
              <section>
                <h6>
                  <b>Cast & Crew</b>
                </h6>
                <span className="details">
                  Produced By Bukpocket Media<br></br>
                  Starring:<br></br>
                </span>
                <pre className="details">{crew}</pre>
              </section>
            </div>
          </div>
          {/* <div className="tab">
            <input type="checkbox" id="chck2" />
            <label className="tab-label" htmlFor="chck2">
              Item 2
            </label>
            <div className="tab-content">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. A, in!
            </div>
          </div> */}
        </div>
      </div>
    </span>
  );
}

// import { useEffect, useRef } from "react";
// import "./../style/cast.scss";

// export default function CastAndCrew() {
//   const acc = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (acc && acc?.current) {
//       const accordionTitle = acc?.current.querySelectorAll(".jsAccordionTitle");
//       for (let i = 0; i < accordionTitle.length; i++) {
//         if (accordionTitle[i]) {
//           accordionTitle[i].addEventListener("click", () =>
//             eventClick(accordionTitle[i])
//           );
//         }
//       }
//       return () => {
//         for (let i = 0; i < accordionTitle.length; i++) {
//           console.log(i);
//           if (accordionTitle[i]) {
//             accordionTitle[i].removeEventListener("click", () =>
//               eventClick(accordionTitle[i])
//             );
//           }
//         }
//       };
//     }
//   }, []);
//   const eventClick = (accordionTitle: any) => {
//     accordionTitle.classList.toggle("is-active");
//     accordionTitle.nextElementSibling!.classList.toggle("is-open");
//   };
//   return (
//     <div className="top_faq_accordion">
//       <div className="top_faq_accordion_container" ref={acc}>
//         <p className="top_faq_accordion_ttl jsAccordionTitle">質問１</p>
//         <p className="top_faq_accordion_content is-open">hello</p>

//         <p className="top_faq_accordion_ttl jsAccordionTitle">質問２</p>
//         <p className="top_faq_accordion_content">サンプルサンプルサンプル</p>

//         <p className="top_faq_accordion_ttl jsAccordionTitle">質問３</p>
//         <p className="top_faq_accordion_content">サンプルサンプルサンプル</p>
//       </div>
//     </div>
//   );
// }
