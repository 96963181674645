import "./../style/menu.scss";
import logo from "./../icon.png";
import { Outlet, Link } from "react-router-dom";

export default function Menu() {
  return (
    <>
      <header className="header">
        <Link to="/" className="logo">
          <img src={logo} height={60} width={130} alt="logo" />
        </Link>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          <li>
            <Link to="/">Stay tuned for 100% drama</Link>
          </li>
        </ul>
      </header>
      <Outlet />
    </>
    // <>
    // <img src={logo} height={200} width={200}/>
    // </>
    // <div className="main-menu logo">
    //   <img src={logo} height={100} width={150}/>
    // {/* <input type="checkbox" id="menu-toggle-side"/>
    // <ul className='menu-list'>
    //   <li className='menu-list-li'>
    //     <a className='menu-list-a' href="#">&#128249;</a>
    //   </li><li className='menu-list-li'>
    //     <a className='menu-list-a' href="#">&#x266b;</a>
    //   </li><li className='menu-list-li'>
    //     <a className='menu-list-a' href="#">&#128249;</a>
    //   </li><li className='menu-list-li'>
    //     <a className='menu-list-a' href="#">&#x266b;</a>
    //   </li>
    //   <li className='menu-list-li'>
    //     <a className='menu-list-a' href="#">&#128249;</a>
    //   </li><li className='menu-list-li'>
    //     <a className='menu-list-a' href="#">&#x266b;</a>
    //   </li>
    // </ul> */}
    // </div>
  );
}
